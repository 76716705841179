/* eslint-disable object-curly-newline */
import { userCollection } from "@/utils/firebase";

export default {
  async getUsers() {
    const querySnapshot = await userCollection.get();

    return querySnapshot;
  },

  async getUser(uid) {
    const docSnap = await userCollection.doc(uid).get();

    return docSnap;
  },

  addUser(data) {
    return userCollection.doc().set(data);
  },
};

export { userCollection };

require("./overrides.scss");

export default {
  theme: {
    themes: {
      light: {
        primary: "#3b5998",
        accent: "#0d6efd",
        secondary: "#8dc63f",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
        white: "#FFFFFF",
      },
      dark: {
        primary: "#3b5998",
        accent: "#0d6efd",
        secondary: "#8dc63f",
        success: "#56CA00",
        info: "#16B1FF",
        warning: "#FFB400",
        error: "#FF4C51",
        white: "#FFFFFF",
      },
    },
  },
};

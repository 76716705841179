/* eslint-disable object-curly-newline */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";

// importing configuration
import firebaseSecrets from "./firebaseSecrets";

firebase.initializeApp(firebaseSecrets);

const db = firebase.firestore();
const auth = getAuth();
const storage = firebase.storage();
const fb = firebase;

const userCollection = db.collection("users");
const moduleCollection = db.collection("modules");
const questionCollection = db.collection("questions");
const subjectCollection = db.collection("subjects");
const schoolCollection = db.collection("schools");
const studentCollection = db.collection("students");
const invitationCollection = db.collection("invitations");

export {
  fb,
  db,
  auth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  storage,
  userCollection,
  moduleCollection,
  questionCollection,
  subjectCollection,
  schoolCollection,
  studentCollection,
  invitationCollection,
};
export default firebase;

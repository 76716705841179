<template>
  <v-snackbar v-model="open" :color="color" :timeout="destroyTime">
    {{ notification.message }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="open = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";
import { setTimeout, clearTimeout } from "timers";

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      destroyTime: 5000,
      open: true,
    };
  },
  computed: {
    color() {
      return this.notification.type || "info";
    },
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.remove(this.notification.id);
    }, this.destroyTime);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: mapActions("notification", ["remove"]),
};
</script>

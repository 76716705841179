/* eslint-disable no-shadow */
import users from "@/services/userService";

export const namespaced = true;

export const state = {
  user: {},
  isLoggedIn: false,
};

export const mutations = {
  setLoggedIn(state, value) {
    state.isLoggedIn = value;
  },
  setUser(state, data) {
    state.user = data;
    localStorage.setItem("user", JSON.stringify(data));
  },
  logout(state) {
    state.user = {};
    state.isLoggedIn = false;
    localStorage.removeItem("user");
  },
};

export const actions = {
  setLoggedIn({ commit }, value) {
    commit("setLoggedIn", value);
  },
  setUser({ commit }, user) {
    if (user) {
      users.getUser(user.uid).then(docs => {
        if (docs.exists) {
          const data = docs.data();
          commit("setUser", data);
        }
      });
    } else {
      commit("setUser", {});
    }
  },
  logout({ commit }) {
    commit("logout");
  },
};

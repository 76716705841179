import "@/plugins/vue-composition-api";
import "@/styles/styles.scss";
import Vue from "vue";
import moment from "moment";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { auth, onAuthStateChanged } from "@/utils/firebase";

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-5MHZE1XTV7" },
});

Vue.filter("formatDate", (value, formatString) => {
  // eslint-disable-next-line no-param-reassign
  formatString = formatString || "YYYY-MM-DD";
  if (value.seconds === undefined && value.seconds === "" && value.seconds === null) return "";
  const myDate = new Date(value.seconds * 1000);

  return moment(myDate).format(formatString);
});

Vue.filter("upText", text => text.charAt(0).toUpperCase() + text.slice(1));
Vue.filter("uppercase", text => text.toUpperCase());

onAuthStateChanged(auth, user => {
  store.dispatch("user/setUser", user);
  store.dispatch("user/setLoggedIn", user !== null);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");

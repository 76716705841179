<template>
  <v-app>
    <v-app-bar app flat color="primary" height="100">
      <div class="boxed-container w-full">
        <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-5">
          <v-row>
            <v-col md="4" sm="4">
              <router-link to="/" class="d-flex align-center text-decoration-none">
                <v-img
                  :src="require('@/assets/images/logos/logo.png')"
                  max-height="100px"
                  max-width="100px"
                  alt="logo"
                  contain
                  eager
                  class="app-logo me-3"
                ></v-img>
                <!-- <v-slide-x-transition>
              <h2 class="app-title text--primary">
                {{ appName }}
              </h2>
            </v-slide-x-transition> -->
              </router-link>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
      <NotificationContainer />
    </v-main>
    <v-footer color="primary lighten-1" class="pt-8" padless>
      <v-row justify="center" no-gutters>
        <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} — <strong>{{ appName }}</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import NotificationContainer from "@/components/NotificationContainer.vue";

export default {
  components: {
    NotificationContainer,
  },
  data: () => ({
    appName: process.env.VUE_APP_TITLE,
  }),
};
</script>

<style></style>

import Vue from "vue";
import VueRouter from "vue-router";
import { auth, onAuthStateChanged } from "@/utils/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/pages/Home.vue"),
    meta: {
      layout: "blank",
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/Login.vue"),
    meta: {
      layout: "blank",
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/modules",
    name: "modules",
    component: () => import("@/views/pages/Module.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/questions",
    name: "questions",
    component: () => import("@/views/pages/question/Index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/questions/create",
    name: "questions-create",
    component: () => import("@/views/pages/question/CreateUpdate.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/questions/edit/:id",
    name: "questions-edit",
    component: () => import("@/views/pages/question/CreateUpdate.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/module/:mid",
    name: "front",
    component: () => import("@/views/pages/Front.vue"),
    meta: {
      layout: "blank",
      requiresAuth: false,
    },
  },

  {
    path: "/pages/account-settings",
    name: "pages-account-settings",
    component: () => import("@/views/pages/account-settings/AccountSettings.vue"),
  },
  {
    path: "/pages/register",
    name: "pages-register",
    component: () => import("@/views/pages/Register.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    onAuthStateChanged(auth, user => {
      if (user) {
        next();
      } else {
        next("/login");
      }
    });
  } else {
    next();
  }
});

export default router;

import Vue from "vue";
import Vuex from "vuex";
import * as user from "./modules/user";
import * as notification from "./modules/notification";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    notification,
  },
});

<template>
  <div class="notification-container">
    <NotificationSnackbar v-for="notification in notifications" :key="notification.id" :notification="notification" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import NotificationSnackbar from "@/components/NotificationSnackbar.vue";

export default {
  components: {
    NotificationSnackbar,
  },
  computed: mapState("notification", ["notifications"]),
};
</script>

<style></style>

import { auth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "../utils/firebase";

export default {
  async loginWithEmailAndPassword(email, password) {
    const user = await signInWithEmailAndPassword(auth, email, password);

    return user;
  },

  async registerUserWithEmailAndPassword(data) {
    const user = await createUserWithEmailAndPassword(auth, data.email, data.password);

    return user;
  },

  async logOut() {
    return auth.signOut();
  },
};
